<template>
  <div class="box">
    <el-form :model="form" label-width="100px" style="width: 400px">
      <el-form-item label="apiKey">
        <el-input v-model="apiKey" />
      </el-form-item>
      <el-form-item label="用户手机">
        <el-input v-model="mobile" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="box">
    <el-table :data="logLists" stripe style="width: 100%">
      <el-table-column prop="mobile" label="手机" width="150" />
      <el-table-column prop="accountId" label="accountId" />
      <el-table-column prop="logUrl" label="logUrl">
        <template #default="scope">
          <div @click="readLog(scope.row.logUrl)" class="cursor">
            {{ scope.row.logUrl }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="createtime" />
    </el-table>
    <el-pagination
      v-if="total"
      small
      layout="prev, pager, next"
      :total="total"
      v-model:current-page="pageNo"
      @update:current-page="changePage"
    />
  </div>
  <el-dialog
    v-model="dialogVisible"
    title="详情"
    width="100%"
    custom-class="dialog"
  >
    <div>
      userAgent:{{ details[0].userAgent }}<br /><br />
      token:{{ details[0].token }}
    </div>
    <el-table :data="details" stripe style="width: 100%">
      <el-table-column prop="key" label="key" width="150" />
      <el-table-column prop="content" label="content" />
      <el-table-column prop="time" label="time" width="180">
        <template #default="scope">
          {{
            new Date(scope.row.time).getFullYear() +
            "-" +
            String(new Date(scope.row.time).getMonth() + 1).padStart(2, "0") +
            "-" +
            String(new Date(scope.row.time).getDate()).padStart(2, "0") +
            " " +
            String(new Date(scope.row.time).getHours()).padStart(2, "0") +
            ":" +
            String(new Date(scope.row.time).getMinutes()).padStart(2, "0") +
            ":" +
            String(new Date(scope.row.time).getSeconds()).padStart(2, "0")
          }}
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      apiKey: "",
      mobile: "",
      pageNo: 1,
      logLists: [],
      total: null,
      dialogVisible: false,
      details: [],
    };
  },
  created() {},
  mounted() {},
  methods: {
    async onSubmit() {
      let res = await this.$api.userLog.logList(
        this.apiKey,
        this.mobile,
        this.pageNo
      );
      this.logLists = res.data.data.list;
      this.total = res.data.data.total;
    },
    changePage() {
      this.onSubmit();
    },
    async readLog(url) {
      try {
        const response = await axios.get(url);
        console.log(response.data);
        this.dialogVisible = true;
        this.details = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style scoped>
.box {
  margin: 20px;
}
.box :deep .el-table .el-table__cell {
  padding: 0;
}
.cursor {
  cursor: pointer;
  padding-left: 0;
  margin-left: 0;
}
</style>
